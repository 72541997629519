import "./Header.css";

function Header(props) {
  const classes = ["Header"];
  if (props.hasOwnProperty("className")) {
    classes.push(props.className);
  }

  return <div className={classes.join(" ")}>{props.children}</div>;
}

export default Header;
