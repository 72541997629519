import "./Section.css";

function Section(props) {
  const classes = ["Section"];
  if (props.hasOwnProperty("className")) {
    classes.push(props.className);
  }
  return <div className={classes.join(" ")}>{props.children}</div>;
}

export default Section;
