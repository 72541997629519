import React from "react";
import { ActionButton } from "../Button/Button";
import Card from "../Card/Card";
import Section from "../Section/Section";
import SubHeader from "../SubHeader/SubHeader";

export default class LessonSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lesson: null,
    };
  }
  onModeSelected(event) {
    if (event.target.value === "") {
      console.log("doing nothing because nothing was done");
      return;
    }
    this.props.onLessonSelect(this.state.lesson, event.target.value);
  }
  lessons() {
    return [{ lesson: "", vocabCount: "" }].concat(
      Object.keys(this.props.vocabData).map((key) => {
        return {
          lesson: key,
          vocabCount: this.props.vocabData[key].vocab.length,
        };
      })
    );
  }
  renderLessonOption(lesson) {
    if (lesson.lesson === "") {
      return (
        <option value="" key="option-default">
          - lessons -
        </option>
      );
    }
    return (
      <option value={lesson.lesson} key={`option-${lesson.lesson}`}>
        Lesson {lesson.lesson} ({lesson.vocabCount})
      </option>
    );
  }
  onLessonSelected(event) {
    if (event.target.value === "") {
      console.log("doing nothing because nothing was done");
      return;
    }
    this.setState({ lesson: parseInt(event.target.value, 10) });
  }
  renderSelectLesson() {
    return (
      <Section>
        <select onChange={this.onLessonSelected.bind(this)}>
          {this.lessons().map(this.renderLessonOption.bind(this))}
        </select>
      </Section>
    );
  }
  renderSelectMode() {
    if (this.state.lesson === null) {
      return null;
    }

    const jToEClicked = (event) => {
      event.target.value = "japanese";
      this.onModeSelected.call(this, event);
    };
    const eToJClicked = (event) => {
      event.target.value = "meaning";
      this.onModeSelected.call(this, event);
    };

    return (
      <Section>
        <div className="action-button-outer-wrap">
          <div className="action-button-inner-wrap">
            <ActionButton onClick={jToEClicked}>
              Japanese &#x2794; English
            </ActionButton>
            <ActionButton onClick={eToJClicked}>
              English &#x2794; Japanese
            </ActionButton>
          </div>
        </div>
      </Section>
    );
  }
  render() {
    return (
      <Card>
        <Section>
          <SubHeader>Select a Lesson</SubHeader>
        </Section>
        {this.renderSelectLesson()}
        {this.renderSelectMode()}
      </Card>
    );
  }
}
