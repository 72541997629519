import React from "react";
import Card from "../Card/Card";
import Section from "../Section/Section";
import SubHeader from "../SubHeader/SubHeader";
import "./Review.css";
import ProgressBar from "../ProgressBar/ProgressBar";
import { Furigana } from "gem-furigana";
import { ActionButton } from "../Button/Button";

const STREAK_TARGET = 4;

const VocabItemJapanese = (props) => {
  console.log({ props });
  const { word, kana, meaning, reading } = props.vocab;
  const answerClasses = ["answer"];
  if (props.showAnswer) {
    answerClasses.push("show");
  }
  const html = { __html: new Furigana(reading).ReadingHtml };
  // const furigana = new Furigana(props.vocab)
  return (
    <div>
      <div className="word" dangerouslySetInnerHTML={html}></div>
      <div className={answerClasses.join(" ")}>{meaning}</div>
    </div>
  );
};

const VocabItemMeaning = (props) => {
  const { word, kana, meaning, reading } = props.vocab;
  const html = { __html: new Furigana(reading).ReadingHtml };
  const answerClasses = ["answer"];
  if (props.showAnswer) {
    answerClasses.push("show");
  }

  return (
    <div>
      <div className="word">{meaning}</div>
      <div
        className={answerClasses.join(" ")}
        dangerouslySetInnerHTML={html}
      ></div>
    </div>
  );
};

export default class Review extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewing: props.lesson.vocab.map((item) => {
        item.streak = 0;
        return item;
      }),
      showAnswer: false,
      mode: props.mode || "meaning",
      done: [],
    };

    console.log(this.state);
  }

  componentDidMount() {
    document.addEventListener("keyup", this.onKeyPressed.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onKeyPressed.bind(this));
  }

  current() {
    return this.state.reviewing[0];
  }

  onKeyPressed(event) {
    console.log("key:", event.which);

    const KEY_RIGHT = 39;
    const KEY_LEFT = 37;
    const KEY_DOWN = 40;

    switch (event.which) {
      case KEY_RIGHT:
        this.onCorrectPressed.call(this, event);
        break;
      case KEY_LEFT:
        this.onIncorrectPressed.call(this, event);
        break;
      case KEY_DOWN:
        this.onRevealPressed.call(this, event);
        break;
    }
  }

  onRevealPressed() {
    if (this.showAnswer) {
      return;
    }

    this.setState({ showAnswer: true });
  }

  onIncorrectPressed() {
    if (!this.state.showAnswer) {
      console.log("answer is not shown yet so not handling shift keys");
      return;
    }

    console.log("incorrect answer...");

    const vocab = this.updateStreak(this.current(), -1);
    this.moveItem(vocab);
  }

  onCorrectPressed() {
    if (!this.state.showAnswer) {
      console.log("answer is not shown yet so not handling shift keys");
      return;
    }
    console.log("correct answer!");

    const vocab = this.updateStreak(this.current(), 1);

    if (vocab.streak > STREAK_TARGET) {
      this.completeItem(vocab);
      return;
    }

    this.moveItem(vocab);
  }

  moveItem(vocab) {
    const position = this.getNewPosition(vocab);
    console.log({
      position,
    });

    const newReviewList = this.state.reviewing;
    newReviewList.shift();

    newReviewList.splice(position, 0, vocab);

    this.setState({ reviewing: newReviewList, showAnswer: false });
  }

  completeItem(vocab) {
    const done = this.state.done;
    const reviewing = this.state.reviewing;
    done.push(vocab);
    reviewing.shift();

    this.setState({
      reviewing,
      done,
      showAnswer: false,
    });
  }

  getNewPosition(vocab) {
    const reviewingTotal = this.state.reviewing.length;
    let newPosition = reviewingTotal * (vocab.streak / STREAK_TARGET);

    console.log({
      reviewingTotal,
      streak: vocab.streak,
      STREAK_TARGET,
      newPosition,
    });

    if (newPosition === 0) {
      newPosition = 1;
    }

    return newPosition;
  }

  updateStreak(vocab, by) {
    vocab.streak += by;

    if (vocab.streak < 0) {
      vocab.streak = 0;
    }

    return vocab;
  }

  percentage() {
    const left = this.state.reviewing.reduce(
      (total, { streak }) => total + streak,
      0
    );
    console.log({ left });
    const done = this.state.done.length * STREAK_TARGET;
    const total =
      (this.state.reviewing.length + this.state.done.length) * STREAK_TARGET;

    const percentage = ((done + left) / total) * 100;
    console.log({ done, total, percentage });
    return percentage;
  }

  /**
   * @param {Boolean} result
   */
  processItem(result) {
    const vocab = this.current();
  }

  renderMode() {
    switch (this.state.mode) {
      case "japanese":
        return (
          <VocabItemJapanese
            vocab={this.current()}
            showAnswer={this.state.showAnswer}
          />
        );
      case "meaning":
        return (
          <VocabItemMeaning
            vocab={this.current()}
            showAnswer={this.state.showAnswer}
          />
        );
    }
  }

  renderReviewing() {
    return this.state.reviewing.map((item) => {
      return <div className="debug">{item.id}</div>;
    });
  }
  renderDone() {
    return this.state.done.map((item) => {
      return <div className="debug">{item.id}</div>;
    });
  }

  renderDebug() {
    return null;

    return (
      <Section>
        <Section className="debug-wrap">{this.renderReviewing()}</Section>
        <Section className="debug-wrap">{this.renderDone()}</Section>
      </Section>
    );
  }

  renderActionButton() {
    return (
      <ActionButton
        className="show-answer-button"
        onClick={this.onRevealPressed.bind(this)}
        title="You can also press <down-arrow>"
      >
        Answer
      </ActionButton>
    );
  }

  renderScoreButtons() {
    return [
      <ActionButton
        className="incorrect-button"
        onClick={this.onIncorrectPressed.bind(this)}
        title="You can also press <left-arrow>"
      >
        Incorrect
      </ActionButton>,
      <ActionButton
        className="correct-button"
        onClick={this.onCorrectPressed.bind(this)}
        title="You can also press <right-arrow>"
      >
        Correct
      </ActionButton>,
    ];
  }
  renderButtons() {
    const buttons = this.state.showAnswer
      ? this.renderScoreButtons()
      : this.renderActionButton();

    return (
      <div className="action-button-outer-wrap">
        <div className="action-button-inner-wrap">{buttons}</div>
      </div>
    );
  }
  render() {
    const current = this.current();
    return (
      <Card className="Review">
        <SubHeader>Reviewing: Lesson {this.props.lesson.lesson}</SubHeader>
        <Section>
          <ProgressBar percentage={this.percentage()} />
        </Section>
        <Section>{this.renderMode()}</Section>
        <Section>{this.renderButtons()}</Section>
        {this.renderDebug()}
      </Card>
    );
  }
}
