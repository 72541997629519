import "./Card.css";

function Card(props) {
  const classes = ["Card"];
  if (props.hasOwnProperty("className")) {
    classes.push(props.className);
  }
  return <div className={classes.join(" ")}>{props.children}</div>;
}

export default Card;
