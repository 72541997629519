import React from "react";
import "./ProgressBar.css";

export default function ProgressBar(props) {
  const innerClasses = ["inner"];
  if (props.percentage <= 0) {
    innerClasses.push("hide");
  }
  return (
    <div className="ProgressBar">
      <div
        className={innerClasses.join(" ")}
        style={{ width: `${props.percentage}%` }}
      >
        <div className="inner-inner"></div>
      </div>
    </div>
  );
}
