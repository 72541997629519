import "./App.css";
import Card from "../Card/Card";
import Header from "../Header/Header";
import Section from "../Section/Section";
import LessonSelect from "../LessonSelect/LessonSelect";
import vocabData from "../../data/vocab-with-lessons.json";
import React from "react";
import Review from "../Review/Review";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lesson: null,
      mode: null,
    };
  }

  onChangeLessonSelected(event) {
    event.preventDefault();
    this.setState({ lesson: null });
  }

  onLessonSelect(lesson, mode) {
    this.setState({
      lesson: vocabData[lesson],
      mode: mode,
    });
  }

  renderState() {
    if (this.state.lesson === null) {
      return (
        <LessonSelect
          onLessonSelect={this.onLessonSelect.bind(this)}
          vocabData={vocabData}
        />
      );
    }

    return <Review lesson={this.state.lesson} mode={this.state.mode} />;
  }
  render() {
    const changeClasses = [];

    if (this.state.lesson === null) {
      changeClasses.push("hide");
    }

    return (
      <div className="App">
        <Card>
          <Section>
            <Header className="app-header">
              Genki Vocab Review
              <div className={changeClasses.join(" ")}>
                <a href="#" onClick={this.onChangeLessonSelected.bind(this)}>
                  Change Lesson
                </a>
              </div>
            </Header>
          </Section>

          {this.renderState()}
        </Card>
      </div>
    );
  }
}

export default App;
