import "./Button.css";

export const ActionButton = (props) => {
  const classes = ["action-button"];
  if (props.hasOwnProperty("className")) {
    classes.push(props.className);
  }
  return (
    <button
      className={classes.join(" ")}
      onClick={props.onClick}
      title={props.title}
    >
      {props.children}
    </button>
  );
};
